import { Options } from '@angular-slider/ngx-slider';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import pc from 'bit-uibl-data-driver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-returnback9',
  templateUrl: './returnback9.component.html',
  styleUrls: ['./returnback9.component.css']
})
export class Returnback9Component implements OnInit {
  manualRefreshEnabled = true;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() stoploader = new EventEmitter<any>();
  @Output() changepolicy = new EventEmitter<any>();
  @Output() startloader = new EventEmitter<any>();
  commaninput: any;
  input_array: any;
  allquotes: any;
  returncoverageoptions: Options = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    keyboardSupport: false,
    animateOnMove: true,
    getPointerColor: (value: number): string => {
      return 'rgb(255, 112, 7)';
    },
    getSelectionBarColor: (value: number): string => {
      return 'rgb(255, 112, 7)';
    },
    stepsArray: [{
      value: 50000,
      legend: '50,000'
    }, {
      value: 100000,
      legend: '100,000'
    }, {
      value: 150000,
      legend: '150,000'
    },
    {
      value: 200000,
      legend: '200,000'
    }, {
      value: 250000,
      legend: '250,000'
    },
    {
      value: 500000,
      legend: '500,000'
    },
    {
      value: 750000,
      legend: '750,000'
    },
    {
      value: 1000000,
      legend: '1,000,000'
    },
    {
      value: 1250000,
      legend: '1,250,000'
    },
    {
      value: 1500000,
      legend: '1,500,000'
    },
    {
      value: 1750000,
      legend: '1,750,000'
    },
    {
      value: 2000000,
      legend: '2,000,000'
    },
    {
      value: 2500000,
      legend: '2,500,000'
    },
    {
      value: 5000000,
      legend: '5,000,000'
    },
    {
      value: 7500000,
      legend: '7,500,000'
    },
    {
      value: 10000000,
      legend: '10,000,000'
    }
    ]
  };
  returntermoptions: Options = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: true,
    hidePointerLabels: true,
    hideLimitLabels: true,
    keyboardSupport: false,
    animateOnMove: true,
    getPointerColor: (value1: number): string => {
      return '#ff7007';
    },
    getSelectionBarColor: (value1: number): string => {
      return '#ff7007';
    },
    stepsArray: [{
      value: 20,
      legend: ''
    }, {
      value: 30,
      legend: ''
    }]
  };

  returncoverage: any;
  returntermLength: any;
  showLoader: boolean;
  quotes = [];
  sorttext: string;
  policytype: any;
  coveragedisplay: any;
  termdisplay: any;
  sortby: any;
  returnpremium: string;
  termplanstartat: string;
  wnsquoteamount: string;
  coveragesilder: any;
  backButton: string;
  clientid: any;
  uniqueId: any;
  displayAddress: any;
  insurancetype: any;
  firstname: any;
  disablerefreshbutton: boolean;
  allplanstartat: string;
  makesblicall: boolean;
  makewnscall: boolean;
  constructor(private pc: ProcessCacheService, public router: Router, private http: HttpClient, private modalService: NgbModal, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger; this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientid = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
    }
    );
    this.disablerefreshbutton = true;
    this.manualRefresh.emit();
    this.sortby = '1';
    this.sorttext = 'Recommended';
    this.returnpremium = localStorage.getItem('returnpremium');
    this.termplanstartat = localStorage.getItem('termpremium');
    this.wnsquoteamount = localStorage.getItem('wnspremium');
    this.allplanstartat = localStorage.getItem('allpolicypremium');
    this.backButton = localStorage.getItem('lifeRecoBackButton');
  }

  public async returnback9(input_array, commonInput) {
    debugger;
    this.firstname = commonInput['firstName'];
    this.returncoverage = commonInput.coverage;
    if (commonInput.term == 20 || commonInput.term == 30) {
      this.returntermLength = commonInput.term;
      this.termdisplay = this.returntermLength;
    }
    else {
      this.returntermLength = 20;
      this.termdisplay = 20;
    }
    if (commonInput.filtersbli) {
      this.makesblicall = false;
      this.makewnscall = false;
    }
    else {
      this.makesblicall = false;//true
      this.makewnscall = false;
    }
    this.coveragedisplay = commonInput.coverage;
    this.coveragesilder = commonInput.coverage;
    this.input_array = input_array;
    commonInput['selectedtype'] = 'ropTerm',
      commonInput['producttypes'] = 'rop_term';
    this.commaninput = commonInput;
    this.http.post<any>(`${environment.Nest_API_URL}/back9/getback9`, input_array).subscribe(async data => {
      debugger;
      this.quotes = [];
      this.showLoader = false;
      if (data.length > 0) {
        this.quotes = data[0];
      }
      else {
        this.quotes = this.quotes;
      }
      for (let i = 0; i < this.quotes.length; i++) {
        if (this.quotes[i]['premium'].toString().includes('.')) {
          this.quotes[i]['premium'] = Number(this.quotes[i]['premium']) / 30;
          this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
          const spt_amt = this.quotes[i]['premium'].toString().split('.');
          if (spt_amt[1].length == 1) {
            spt_amt[1] = spt_amt[1] + '0';
          }
          this.quotes[i].before_dcimal = spt_amt[0];
          this.quotes[i].after_dcimal = '.' + spt_amt[1];
        }
        else {
          this.quotes[i]['premium'] = Number(this.quotes[i]['premium']) / 30;
          this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
          this.quotes[i].before_dcimal = this.quotes[i]['premium'];
          this.quotes[i].after_dcimal = '.00';
        }
        if (this.quotes[i].carrier['name'] === 'SBLI') {
          this.quotes[i].highlight = 'Instant Decision';
          this.quotes[i].point1 = 'Completely digital experience with no medical exam, phone calls or in-person appointments required.';
          this.quotes[i].point2 = 'Easy online application that you can start and finish in minutes, with a decision on the spot.';
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }

        else if (this.quotes[i].carrier['name'] === 'Banner') {
          this.quotes[i].highlight = 'Flexible Coverage';
          this.quotes[i].point1 = 'You may be eligible for an instant decision.';
          this.quotes[i].point2 = 'Based on your answers to underwriting questions, an exam may not be required.';
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }
        else if (this.quotes[i].carrier['name'] === 'Next By Pacific Life') {
          this.quotes[i].highlight = 'Flexible Coverage';
          this.quotes[i].point1 = 'Let’s you easily increase or decrease your coverage to keep up with life’s changes.';
          this.quotes[i].point2 = 'Locks in your original health rating so future increases are more affordable.';
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }
        else if (this.quotes[i].carrier['name'] === 'Transamerica') {
          if (this.quotes[i].product['name'] === `Trendsetter LB ${this.returntermLength}`) {
            this.quotes[i].highlight = 'Living Benefits';
            this.quotes[i].point1 = 'You can accelerate your death benefit for chronic and critical illness.';
            this.quotes[i].point2 = 'Based on your answers to underwriting questions, an exam may not be required.';

          }
          else {
            this.quotes[i].highlight = 'No Physical Exam*';
            this.quotes[i].point1 = 'Terminal Illness Accelerated Death Benefit (ADB) Endorsement';
            this.quotes[i].point2 = '';
          }
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }
        else if (this.quotes[i].carrier['name'] === 'North American') {
          this.quotes[i].highlight = 'Living Benefits';
          this.quotes[i].point1 = 'Accelerated death benefits can accelerate a portion of the death benefit, should the insured become critically, chronically, or terminally ill.';
          this.quotes[i].point2 = 'Children’s insurance rider allows you to insure your child’s life at great rates too.';
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }
        else if (this.quotes[i].carrier['name'] === 'Prudential Financial') {
          this.quotes[i].highlight = 'No Physical Exam*';
          this.quotes[i].point1 = 'Conversion privilege allows you to convert the policy to any permanent policy we offer, guaranteed.';
          this.quotes[i].point2 = 'If you become terminally ill, our Living Needs Benefit will pay out a portion of your death, benefit while you’re still living.';
          this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
          this.quotes[i].benefit2 = 'Accidental death';
          this.quotes[i].benefit3 = 'Waiver of premium';
          this.quotes[i].benefit4 = 'Child term';
        }
        else {
          this.quotes[i].highlight = 'No Physical Exam*';
          this.quotes[i].point1 = 'When your policy ends, the additional premium for the return of premium benefit will be returned to you in a lump sum.';
          this.quotes[i].point2 = 'Convertible to age 65 and 2 years prior to end of the level term to any non medical permanent product';
          this.quotes[i].benefit1 = '';
          this.quotes[i].benefit2 = '';
          this.quotes[i].benefit3 = '';
          this.quotes[i].benefit4 = '';
          this.quotes[i].policy_type = 'Return of Premium';
          if (Number(this.returntermLength) == 20) { this.quotes[i].productid = 2788; }
          else if (Number(this.returntermLength) == 30) { this.quotes[i].productid = 2787; }
        }

        this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
      }
      const test = [];
      this.quotes = this.quotes.filter(x => x.carrier['name'] === 'Banner' || x.carrier['name'] === 'Assurity' || x.carrier['name'] === 'Next By Pacific Life' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'Transamerica' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial');
      const sbliquotes = this.quotes.filter(x => x.carrierId === 'sbli');
      const paclife = this.quotes.filter(x => x.carrierId === 'paclife');
      const banner = this.quotes.filter(x => x.carrier['name'] === 'Banner');
      const other = this.quotes.filter(x => x.carrierId != 'paclife' && x.carrierId != 'sbli' && x.carrier['name'] != 'Banner');
      this.quotes = test.concat(sbliquotes, paclife, banner, other);
      this.returncoverage = commonInput.coverage;
      this.stoploader.next();
      this.allquotes = JSON.stringify(this.quotes);

      // this.stoploader.next();
      console.log('quotes', this.quotes);
    }, (error) => {
      this.showLoader = false;
      this.quotes = [];
      console.log('An unexpected error occured');
      console.log(error);
    }, () => {
      console.log('completed');
    });
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, windowClass: 'custom-modal' });
  }
  openVerticallyCentered1(contentpolicy) {
    this.modalService.open(contentpolicy, { centered: true, windowClass: 'custom-modal' });
  }
  openVerticallyCentered2(coverage) {
    document.getElementById('coveragemodal').style.display = 'block';
  }

  public changepolicyType(id) {
    debugger;
    this.policytype = id;
    this.pc.lifesummary({'filter':'Yes'})
    if (id == '1') {
      this.changepolicy.next(id);
    }
    else if (id == '2') {
      this.changepolicy.next(id);
    }
    else if (id == '3') {
      this.changepolicy.next(id);
    }
    else if (id == '4') {
      this.changepolicy.next(id);
    }
    this.modalService.dismissAll();
  }
  public refreshQuote() {
    debugger;
    this.pc.lifesummary({'coverage_refresh':'Yes'});
    document.getElementById('coveragemodal').style.display = 'none';
    this.startloader.emit();
    this.commaninput['coverage'] = this.returncoverage.toString();
    this.commaninput['term'] = this.returntermLength;
    this.input_array['quotes'][0].product_categories = [`${this.returntermLength} Year Return of Premium Term`];
    this.returnback9(this.input_array, this.commaninput);
  }
  public closemodal() {
    document.getElementById('coveragemodal').style.display = 'none';
  }
  sortQuotes(id, text) {
    debugger;
    this.sortby = id;
    this.sorttext = text;
    if (id == '1') {
      this.quotes = JSON.parse(this.allquotes);
    }
    else if (id == '2') {
      this.quotes = this.quotes.sort((a, b) => a.premium - b.premium);
    }
    else {
      this.quotes = this.quotes.sort((a, b) => b.premium - a.premium);
    }
    this.pc.lifesummary({'sort':'Yes'})
  }
  async selectPlan(qts) {
    debugger;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_quote_amount: `$${qts.before_dcimal}${qts.after_dcimal}`,
      life_insurance_carrier: `${qts.carrier.name}_${qts.product.name}`,
      desired_life_coverage: this.returncoverage,
      coverage_term_life: this.returntermLength,
      desired_life_term: this.returntermLength,
      life_insurance_type: `Term ${this.returntermLength} Years`
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // let newTab = window.open();
    // newTab.location.href=`applicationflow?firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${qts.productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
    //window.open(`applicationflow?firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${qts.productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`, '_blank');
    //this.router.navigateByUrl();

  }

  public sliderEvent(even) {
    debugger;
    this.coveragesilder = even.value;
    this.disablerefreshbutton = false;
  }
  public sliderEvent1(even) {
    debugger;
    this.returntermLength = even.value;
    this.disablerefreshbutton = false;
  }
  previousscreen() {


    if (this.backButton == 'PrefillResumeFlowLife') {
      location.href = 'PrefillResumeFlowLife';
    }
    else if (this.backButton == 'life-insurance') {
      location.href = 'life-insurance';
    }
    else if (this.backButton == 'CoupleAboutToGetMArriedLife') {
      location.href = 'CoupleAboutToGetMArriedLife';
    }
    else if (this.backButton == 'CoupleWithChildrenLife') {
      location.href = 'CoupleWithChildrenLife';
    }
    else if (this.backButton == 'SingleMotherLife') {
      location.href = 'SingleMotherLife';
    }
    else if (this.backButton == 'SingleParentLife') {
      location.href = 'SingleParentLife';
    }
    else if (this.backButton == 'PrefillKVLife') {
      location.href = 'bubble-Life-kellerwilliams';
    }
    else {
      location.href = `QuoteFlowV2Life/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/previous/1`;
    }

    //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
  }
}
