import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Back9AllQuotesComponent } from 'src/app/components/back9-all-quotes/back9-all-quotes.component';
import { Returnback9Component } from 'src/app/components/returnback9/returnback9.component';
import { Termlifeback9Component } from 'src/app/components/termlifeback9/termlifeback9.component';
import { WNSback9Component } from 'src/app/components/wnsback9/wnsback9.component';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { ValidationService } from '../../shared/validation.service';

@Component({
  selector: 'app-back9-reco-screen',
  templateUrl: './back9-reco-screen.component.html',
  styleUrls: ['./back9-reco-screen.component.css']
})
export class Back9RecoScreenComponent implements OnInit, AfterViewInit {

  @ViewChild('content', { static: true }) content: ElementRef;


  // @HostListener('window:unload', [ '$event' ])
  // unloadHandler(event) {

  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHander(event) {
  //   return false;
  // }

  @Output() loaderText = [
    {
      'title1': 'Calculating coverage amount',
      'Subtitle1': 'Add more at a later time if you need to',
      'title2': 'Calculating term',
      'Subtitle2': 'Extend it or reduce as you see needed',
      'title3': 'Estimating premium',
      'Subtitle3': 'Lock it in for decades'
    }];
  decisiontext = 'You are just seconds away from getting personalized life insurance quotes to protect your loved ones';
  detailsText = 'More plan details';
  hide: boolean;
  quotes: any;
  clientid: any;
  existingData: any;
  mortgage: any;
  kids: any;
  annualIncome: any;
  dob: any;
  age: any;
  termLength: any;
  showLoader: boolean;
  coverage: any;
  testcoverage: any;
  quickQuoteData: any;
  uniqueId: any;
  commonAddress: any;
  city: any;
  zipcode: any;
  state: any;
  height: any;
  weight: any;
  riskClass: string;
  fullName: any;
  emailid: string;
  selectedCardData: any;
  displayAddress: any;
  insurancetype: any;
  policytype: any;
  termtype: any;
  sortby: any;
  allquotes: any;
  firstname: any;
  lastname: any;
  sorttext: any;
  health: any;
  showonlywns: boolean;
  wnsquote: any;
  coveragevaluechanged: any;
  smoker: any;
  wnscoverage: any;
  termlifecall: boolean;
  decreasingterm: boolean;
  returnperimiumcalled: any;
  @ViewChild(WNSback9Component, { static: false }) wnsback9component: WNSback9Component;
  @ViewChild(Termlifeback9Component, { static: false }) termlifecomponent: Termlifeback9Component;
  @ViewChild(Returnback9Component, { static: false }) Returnback9component: Returnback9Component;
  @ViewChild(Back9AllQuotesComponent, { static: false }) Back9AllQuotesComponent: Back9AllQuotesComponent;
  input_array: any;
  commonInput: any;
  phone: any;
  back9hight: any;
  loader: any;
  yearm: any;
  shorturl: any;
  showNumber: number;
  firstquestion: any;
  secondquestion: any;
  thirdquestions: any;
  fourthquestion: any;
  nextquestions: boolean;
  nextpage: boolean;
  nextnoneapply: boolean;
  notselectedquestions: boolean;
  selectednonapply: boolean;
  modalopened: boolean;
  selectedmeridum: string;
  schedulecall: boolean;
  popupclosed: boolean;
  callback_minutes: any;
  callback_hour: any;
  callback_time: string;
  appointmentdate: any;
  back9modalopened: boolean;
  hours = ['08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07'];
  minutes = ['00', '30'];
  merdiaum = ['AM', 'PM'];
  redirecturl: any;
  coveragedisplay: any;
  termdisplay: any;
  productname: any;
  Online: boolean;
  dateValidation: string;
  minDate;
  personaldetails: boolean;
  headertext: any;
  backbutton: boolean;
  message: string;
  is_valid: boolean;
  tobacoUser: any;
  maleicon: any;
  femaleicon: string;
  sbligender: any;
  heightFt: any;
  heightIn: any;
  phoneerror: boolean;
  tobacoselected: string;
  coverageError: string;
  prevEmailId: string;
  ShowDollar_income: boolean;
  coverageshow: any;
  emailError: string;
  organicFlow: any;
  showPersonalDetails: any;
  showPopup: any;
  filtersbli: any;
  allFieldsAvailable: any;
  popUpTriggered: boolean;
  koQuestionsShown: boolean;
  wnssbliCarrier: string;
  allThreePopup = false;
  carrierName: any;
  questionSelected: boolean;
  pd_pop_up: any;
  koSubmission: any;
  carriername: any;
  quoteamount: any;
  constructor(public ValidationService: ValidationService, public router: Router, private utilityService: UtilityFunctionsService, private activatedroute: ActivatedRoute, private http: HttpClient, private pc: ProcessCacheService, private modalService: NgbModal, config: NgbModalConfig) {
  }

  async ngOnInit(): Promise<void> {


    this.modalopened = true;
    this.loader = true;
    this.message = '';
    this.coverageError = '';
    this.emailError = '';
    this.ShowDollar_income = true;
    this.pc.logPageLandingtoBO({ page_name: 'life_recopage' });
    this.personaldetails = true;
    this.showNumber = 0;
    this.notselectedquestions = true;
    // this.showLoader = true;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientid = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
    }
    );
    const urls = {
      'urls': [`${window.location.hostname}/resumeFlow/${this.clientid}`]
    };


    await this.getshortURL(urls);
    this.minDate = new Date();
    //this.getest();

  }
  public async getest(type = 0) {

    const bothback9andquickquote = await Promise.all([this.getQuotes_back9(), this.getQuote(),]).then(function (values) {
      console.log(values);
      return values;
    });
    if (type == 1) {
      this.navigatetopage();
    }
  }


  public async getQuotes_back9() {
    debugger;

    if (this.mortgage != null) {
      this.mortgage = this.mortgage.split(',').join('');
    }
    else {
      this.mortgage = 0;
    }
    this.back9hight = Number(localStorage.getItem('bubble-heightFt')) * 12 + Number(localStorage.getItem('bubble-heightIn'));
    if (this.annualIncome != undefined || !!this.annualIncome) { this.annualIncome = this.annualIncome.split(',').join(''); }
    if (this.age != undefined || !!this.age) { this.age = this.age?.split(',').join(''); }
    if (this.kids != undefined || !!this.kids) { this.kids = this.kids.split(',').join(''); }

    const recomendedCoverage = '100000';
    if (localStorage.getItem('bubble-coverage') == null || localStorage.getItem('bubble-coverage') == undefined || localStorage.getItem('bubble-coverage') == '') {
      localStorage.setItem('bubble-coverage', recomendedCoverage);
      this.coverage = recomendedCoverage.toString();
    }
    else {
      this.coverage = localStorage.getItem('bubble-coverage');
    }
    console.log('cv', this.coverage);
    this.testcoverage = this.coverage;
    if (localStorage.getItem('bubble-termLength') == null || localStorage.getItem('bubble-termLength') == undefined || localStorage.getItem('bubble-termLength') == '') {
      this.termLength = 20;
      // const calculatedTerm = (65 - Number(this.age));
      // this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
      // this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? '15' : Number(this.termLength);
      // this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? '10' : Number(this.termLength);
      // this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
      localStorage.setItem('bubble-termLength', this.termLength);
    }
    else {
      this.termLength = localStorage.getItem('bubble-termLength');
    }
    this.pc.back9coverage = this.coverage;
    this.pc.back9term = this.termLength;
    this.clientid = localStorage.getItem('clientID');
    let cov = Number(this.coverage);
    cov = cov / Number(this.termLength);
    cov = cov / 12;
    if (cov > 10000) {
      this.wnscoverage = 10000;
    } else if (cov < 500) {
      this.wnscoverage = 500;
    } else {
      this.wnscoverage = cov;
    }
    this.wnscoverage = this.wnscoverage.toString();
    if (this.wnscoverage.includes('.')) {
      const wnscov = this.wnscoverage.split('.');
      this.wnscoverage = wnscov[0];
    }
    localStorage.setItem('bubble-wnscoverage', this.wnscoverage);
    // this.pc.getCoverageRecommendation(this.mortgage, this.annualIncome, this.age, this.kids).then(response => {
    //

    // })
    //   .catch(error => {
    //     this.showLoader = false;
    //     console.log('getCoverageRecommendation', error);
    //   });

  }


  public async getQuote() {
    debugger;

    //this.input_array_smoker();
    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
      this.emailid = localStorage.getItem('bubble-email');

    }
    else {
      this.fullName = 'Anonymous User';
    }
    this.commonAddress = localStorage.getItem('bubble-street');
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    await this.pc.getAppData(this.clientid).then(data => {
      this.loader = false;
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body;
      this.firstname = this.existingData.bubble_fname;
      this.lastname = this.existingData.bubble_lname;
      // this.dob = this.existingData.dob;
      this.emailid = localStorage.getItem('bubble-email');
      this.age = localStorage.getItem('bubble-age');
      this.mortgage = localStorage.getItem('bubble-mortgage');
      this.kids = localStorage.getItem('bubble-kids');
      this.annualIncome = localStorage.getItem('bubble-annualIncome');
      this.height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
      this.phone = localStorage.getItem('bubble-phone');
      this.dob = localStorage.getItem('bubble-dob');
      this.organicFlow = dataFrom.body.organicFlow;
      this.tobacoUser = dataFrom.body.tobacoUser;
      this.showPopup = dataFrom.body.showPopup;
      this.filtersbli = dataFrom.body.filtersbli;
      this.pd_pop_up = dataFrom.body?.pd_pop_up;
      this.koSubmission = dataFrom.body?.w_s__sbli_ko_popup_submission;
      // this.phone = (!this.existingData['bubble_phone']) ? this.existingData['phone'] : this.existingData['bubble_phone'];
      if (this.existingData.tobacoselected === 'No') {
        this.smoker = 'Never';
        this.tobacoUser = '5';
      }
      else {
        if (this.existingData.tobacoUsageDuration == '1') {
          this.smoker = 'Currently';
        }
        else {
          this.smoker = 'Previously';
        }
        this.tobacoUser = this.existingData.tobaccoUsage;
      }
      if ((this.tobacoUser == undefined || this.tobacoUser == 'Yes') && this.existingData.tobacoUsageDuration == undefined) {
        this.tobacoUser = '';
      }
      if (this.dob.includes('-')) {
        const date_birth = this.dob.split('-');
        this.dob = date_birth[1] + '/' + date_birth[0] + '/' + date_birth[2];
      }
      this.phonelength();
      this.popUpTriggered ? 0 : this.triggerPopupCondition();
    }
    ).catch(error => {
      console.log('error', error);
    });
    await this.pc.parseAddressLocalAndPatch(fullAddress, this.clientid).then(
      async data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        this.city = data.city_name;
        this.zipcode = data.zip;
        console.log('fullAddressNew : ', fullAddress);
        const sbligender = localStorage.getItem('bubble-gender');// == "Male" ? "1" : "0";
        this.sbligender = sbligender;
        if (sbligender == '0') {
          this.femaleicon = 'assets/images/active/icon-female-active.svg';
          this.maleicon = 'assets/images/images/icon-male.png';

        }
        else {
          this.sbligender = '1';
          this.maleicon = 'assets/images/active/icon-male-active.svg';
          this.femaleicon = 'assets/images/images/ic-female.png';
        }
        const sblitobacco = localStorage.getItem('bubble-tobacco');
        const d = new Date(this.dob);
        const year = d.getFullYear();
        console.log('year : ', year, '# this.age:', this.age, '#this.dob:', this.dob);
        const sbliDob = this.dob;
        const splitname = this.fullName.split(' ');         // //debugger

        const localterm = localStorage.getItem('bubble-termLength');
        this.riskClass = localStorage.getItem('riskClass');
        console.log('termLength3', this.termLength);
        if (this.riskClass === 'Super Preferred Non-Tobacco') {
          this.health = 5;
        }
        else if (this.riskClass === 'Preferred Plus Non-Tobacco') {
          this.health = 4;
        }
        else if (this.riskClass === 'Preferred Non-Tobacco') {
          this.health = 3;
        }
        else if (this.riskClass === 'Preferred Tobacco') {
          this.health = 2;
        }
        else {
          this.health = 5;
        }
        this.commonInput = {
          displayAddress: this.displayAddress,
          insurancetype: this.insurancetype,
          clientid: this.clientid,
          uniqueId: this.uniqueId,
          lastName: this.lastname ? this.lastname : 'Lastname',
          firstName: this.firstname ? this.firstname : 'Firstname',
          middleName: splitname[1] ? splitname[1] : 'MiddleName',
          addressType: 'PhysicalRisk',
          address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
          city: this.city,
          gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
          dob: sbliDob,//inputJson.dob
          term: this.termLength,
          wnsterm: this.termLength,//inputJson.term
          zip: this.zipcode,//inputJson.zip
          state: this.state,//inputJson.state
          height: this.height,//inputJson.height
          weight: +this.weight,//inputJson.weight
          back9hight: this.back9hight,
          tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
          riskClass: this.riskClass,
          wnscoverage: this.wnscoverage.toString(),
          smoker: this.smoker,
          coverage: this.coverage.toString(),//inputJson.coverage
          phone: this.phone.replace(/\$|-/g, ''),//inputJson.email
          premiumFrequency: 'm',
          mode: 12,
          health: this.health,
          makewnscall: this.existingData['makewnscall'],
          makesblicall: this.existingData['makesblicall']
        };
        console.log('commaninput', this.commonInput);

        //this.onlyback9(input_array, commonInput);
      })
      .catch(error => {
        if (error) console.log(error);
        else console.log('select-life-carrier\\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
      });
  }


  async policyType(id) {
    debugger;

    await this.pc.getAppData(this.clientid).then(data => {
      this.loader = false;
      const dataFrom = JSON.parse(data.Payload);
      this.filtersbli = dataFrom.body.filtersbli;
    }
    ).catch(error => {
      console.log('error', error);
    });

    console.log(id, 'policyType');
    let filtersbli;
    this.showLoader = true;
    this.policytype = id;
    if (id == '1') {
      this.termtype = ' Year Term';

      this.input_array_smoker();
      this.commonInput['selectednonapply'] = this.selectednonapply;
      if (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1 || this.filtersbli == 'true') {
        filtersbli = true;
        this.commonInput['filtersbli'] = filtersbli;
      }
      else {
        filtersbli = false;
        this.commonInput['filtersbli'] = filtersbli;
      }
      setTimeout(() => {
        this.termlifecomponent?.onlyback9(this.input_array, this.commonInput);
      }, 0);

    }
    else if (id == '2') {
      this.input_array_smoker();
      setTimeout(() => {
        this.commonInput.filtersbli = this.filtersbli == 'true' ? true : false;
        this.wnsback9component?.getwnsQuote(this.commonInput, this.uniqueId);
      }, 100);
    }
    else if (id == '3') {
      this.termtype = ' Year Return of Premium Term';
      this.input_array_smoker();
      setTimeout(() => {
        this.Returnback9component?.returnback9(this.input_array, this.commonInput);
      }, 0);
    }
    else if (id == '4') {
      this.termtype = ' Year Term';
      this.commonInput['selectednonapply'] = this.selectednonapply;
      if (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1 || this.filtersbli == 'true') {
        filtersbli = true;
        this.commonInput['filtersbli'] = filtersbli;
      }
      else {
        filtersbli = false;
        this.commonInput['filtersbli'] = filtersbli;
      }
      this.input_array_smoker();
      setTimeout(() => {
        this.commonInput.filtersbli = this.filtersbli == 'true' ? true : false;
        this.Back9AllQuotesComponent?.getAllQuotes(this.input_array, this.commonInput);
      }, 0);
    }
  }

  public hideloader(spinner = 0) {
    this.modalopened = false;
    if (spinner == 0) {
      this.showLoader = false;

    }
    else if (spinner == 2) {
      this.loader = false;
    }
    else {
      this.loader = true;
    }
  }
  public spinloader(spinner = 0) {

    if (spinner == 0) {
      this.showLoader = true;
    }
    else {
      this.loader = true;
    }
  }

  public input_array_smoker() {
    debugger;
    this.termLength = this.pc.back9term;
    this.coverage = this.pc.back9coverage;
    let dob;
    if (this.dob != undefined) {
      if (this.dob.includes('/')) {
        const date_birth = this.dob.split('/');
        dob = date_birth[2] + '-' + date_birth[0] + '-' + date_birth[1];
      }
    }

    if (this.existingData.tobacoselected === 'No') {
      this.smoker = 'Never';
      this.input_array = {
        'quotes': [{
          'face_amounts': [this.coverage],
          'gender': (this.existingData.gender == '1') ? 'Male' : 'Female',
          'birthdate': dob,
          'state': this.existingData.state_name ? this.existingData.state_name : this.existingData.stateCode,
          'health': 5,
          'smoker': this.smoker,
          'mode': 12,
          //"product_categories":["30 Year Return of Premium Term"]
          'product_categories': [`${this.termLength}${this.termtype}`, `${this.termLength} Year Return of Premium Term`]
        }]
      };
      console.log('back9_input_Never', this.input_array);
    }
    else {
      if (this.existingData.tobacoUsageDuration == '1') {
        this.smoker = 'Currently';
        this.input_array = {
          'quotes': [{
            'face_amounts': [this.coverage],
            'gender': (this.existingData.gender == '1') ? 'Male' : 'Female',
            'birthdate': dob,
            'state': this.existingData.state_name ? this.existingData.state_name : this.existingData.stateCode,
            'health': 5,
            'smoker': this.smoker,
            'usages': [{
              'status': 'Currently',
              'frequency': 2,
              'category': 'Cigarettes'
            }
            ],
            'mode': 12,
            //"product_categories":["30 Year Return of Premium Term"]
            'product_categories': [`${this.termLength}${this.termtype}`, `${this.termLength} Year Return of Premium Term`]
          }]
        };
        console.log('back9_input_Currently', this.input_array);
      }
      else {
        this.smoker = 'Previously';
        if (this.existingData.tobacoUsageDuration == '2') {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 13);
          this.yearm = formatDate(d, format, locale);
        }
        else if (this.existingData.tobacoUsageDuration == '3') {

          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 25);
          this.yearm = formatDate(d, format, locale);
        }
        else if (this.existingData.tobacoUsageDuration == '4') {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 37);
          this.yearm = formatDate(d, format, locale);
        }
        else {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 60);
          this.yearm = formatDate(d, format, locale);
        }
        this.input_array = {
          'quotes': [{
            'face_amounts': [this.coverage],
            'gender': (this.existingData.gender == '1') ? 'Male' : 'Female',
            'birthdate': dob,
            'state': this.existingData.state_name ? this.existingData.state_name : this.existingData.stateCode,
            'health': 5,
            'smoker': this.smoker,
            'usages': [{
              'status': 'Previously',
              'last_use_date': this.yearm,
              'frequency': 2,
              'category': 'Cigarettes'
            }
            ],
            'mode': 12,
            //"product_categories":["30 Year Return of Premium Term"]
            'product_categories': [`${this.termLength}${this.termtype}`, `${this.termLength} Year Return of Premium Term`]
          }]
        };
        console.log('back9_input_Previously', this.input_array);
      }
    }
    if (this.policytype == '3') {
      if (Number(this.termLength != 20) || Number(this.termLength != 30)) {
        this.input_array['quotes'][0].product_categories = ['20 Year Return of Premium Term'];
        console.log('Reutn_back9_input', this.input_array);
      }
    }
  }

  async getshortURL(url) {

    this.age = localStorage.getItem('bubble-age');
    if (localStorage.getItem('bubble-termLength') == null || localStorage.getItem('bubble-termLength') == undefined || localStorage.getItem('bubble-termLength') == '') {
      this.termLength = 20;
      // const calculatedTerm = (65 - Number(this.age));
      // this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
      // this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? '15' : Number(this.termLength);
      // this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? '10' : Number(this.termLength);
      // this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
      localStorage.setItem('bubble-termLength', this.termLength);
    }
    else {
      this.termLength = localStorage.getItem('bubble-termLength');
    }


    this.http.post<any>('https://app.getmybubble.co/api/url-shortener', url).subscribe(async data => {

      this.shorturl = data[0].shortUrl;
      this.coveragevaluechanged = true;
      this.policytype = '1';
      this.sorttext = 'Recommended';
      this.hide = true;
      this.sortby = '1';
      this.termtype = ' Year Term';
      this.termlifecall = true;
      this.decreasingterm = false;
      this.getest();
    }, async (error) => {
      this.pc.hubSpotSyncAfterEmailFetch({
        email: localStorage.getItem('bubble-email'),
        user_landed_reco_page: 'Yes',
        application_stage: 'Quote',
        life_insurance_type: `Term ${this.termLength} Years`
      });
      this.coveragevaluechanged = true;
      this.policytype = '1';
      this.sorttext = 'Recommended';
      this.hide = true;
      this.sortby = '1';
      this.termtype = ' Year Term';
      this.termlifecall = true;
      this.decreasingterm = false;
      this.getest();
      console.log(error);
    }, () => {
      console.log('completed');
    });
  }

  ngAfterViewInit(): void {
    //
    // document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  }

  openModal() {
    debugger;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    // if (!this.showLoader && !this.loader && !this.back9modalopened) {
    //   this.modalopened = true;
    //   this.modalService.open(this.content, ngbModalOptions);
    // }
    if (!this.back9modalopened) {
      this.modalopened = true;
      this.modalService.open(this.content, ngbModalOptions);
    }
  }

  exitPopup(e) {
    if (this.showNumber == 0 && e.clientY < -1 && !this.loader && !this.showLoader && !this.modalopened) {

      document.getElementById('exiterrorModalreco').style.display = 'block';
    }
  }

  closePopup() {

    document.getElementById('exiterrorModalreco').style.display = 'none';
    this.showNumber = 1;
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
  }

  public navigatetopage() {
    debugger;
    setTimeout(() => {
      if (this.existingData['selectedLife'] == 'wns' && this.existingData['policytypeid'] == 2) {
        this.policyType('2');
      }
      else if (this.existingData['policytypeid'] == 1) {
        this.policyType('1');
      }
      else {
        this.policyType('4');
      }
    }, 0);
  }
  public noneApply() {
    this.headertext = 'Almost there! Help us get you most accurate quotes from our 20+ carrier partners by answering these questions';
    this.backbutton = true;
    this.nextquestions = true;
    this.nextpage = true;
    this.nextnoneapply = true;
    this.questionsarry = [];
    this.firstquestion = '';
    this.secondquestion = '';
  }
  public noneApply1() {
    debugger;
    this.questionSelected = this.questionsarry.length >= 1 || this.questionsarry1.length >= 1;
    this.koQuestionsShown = true;
    this.koSubmission = 'true';
    this.policytype == '1' && !this.questionSelected ? this.termlifecomponent.redirectWnsSbliAfterPopup(this.wnssbliCarrier) : this.policytype == '2' && !this.questionSelected ? this.wnsback9component.redirectWnsSbliAfterPopup(this.wnssbliCarrier) : this.policytype == '4' && !this.questionSelected ? this.Back9AllQuotesComponent.redirectWnsSbliAfterPopup(this.wnssbliCarrier) : this.policytype == '1' ? this.termlifecomponent.removesbliandwns() : this.policytype == '2' ? this.wnsback9component.removesbliandwns() : this.Back9AllQuotesComponent.removesbliandwns();
    this.questionsarry1 = [];
    this.selectednonapply = true;
    this.notselectedquestions = false;
    this.modalopened = false;
    this.modalService.dismissAll();
    const savedata = {
      'id': this.clientid,
      'clientId': this.clientid,
      'showPopup': 'false',
      'filtersbli': (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1) ? 'true' : 'false',
      'w_s__sbli_ko_popup_submission': 'true',
    };
    this.pc.saveUpdateClientData(savedata);
    this.hubspotApplicationStage('Quote');
    // setTimeout(() => {
    //   if (this.policytype == '4') {
    //     this.Back9AllQuotesComponent.popupopend(this.selectednonapply);
    //     if (this.questionsarry.length >= 1) {
    //       this.Back9AllQuotesComponent.removesbliandwns();
    //     }
    //     else {
    //       this.Back9AllQuotesComponent.popupopend(true);
    //     }
    //   }
    //   else {
    //     if (this.questionsarry.length >= 1) {
    //       this.termlifecomponent.removesbliandwns();
    //     }
    //     else {
    //       this.termlifecomponent.popupopend(true);
    //     }
    //   }

    // }, 0);
    // let filtersbli;
    // if (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1) {
    //   filtersbli = true;
    //   this.commonInput['filtersbli'] = filtersbli;
    // }
    // else {
    //   filtersbli = false;
    //   this.commonInput['filtersbli'] = filtersbli;
    // }
    // setTimeout(() => {
    //   this.showLoader = true;
    //   this.Back9AllQuotesComponent.getAllQuotes(this.input_array, this.commonInput);
    // }, 0);
  }
  public next() {

    this.headertext = 'Almost there! Help us get you most accurate quotes from our 20+ carrier partners by answering these questions';
    this.backbutton = true;
    this.personaldetails = false;
    this.nextquestions = true;
    this.nextnoneapply = true;
    this.nextpage = true;
  }
  questionsarry = [];
  questionsarry1 = [];
  public questionChecked(qsn) {

    if (qsn === '1') {
      if (!this.questionsarry.includes(qsn)) {
        this.questionsarry.push(qsn);
        this.firstquestion = qsn;
      }
      else {
        const index = this.questionsarry.indexOf(qsn);
        if (index > -1) {
          this.questionsarry.splice(index, 1);
        }
        this.firstquestion = '';
      }
    }
    else if (qsn === '2') {
      if (!this.questionsarry.includes(qsn)) {
        this.questionsarry.push(qsn);
        this.secondquestion = qsn;
      }
      else {
        const index = this.questionsarry.indexOf(qsn);
        if (index > -1) {
          this.questionsarry.splice(index, 1);
        }
        this.secondquestion = '';
      }
    }
    else if (qsn === '3') {
      if (!this.questionsarry1.includes(qsn)) {
        this.questionsarry1.push(qsn);
        this.thirdquestions = qsn;
      }
      else {
        const index = this.questionsarry1.indexOf(qsn);
        if (index > -1) {
          this.questionsarry1.splice(index, 1);
        }
        this.thirdquestions = '';
      }
    }
    else {
      if (!this.questionsarry1.includes(qsn)) {
        this.questionsarry1.push(qsn);
        this.fourthquestion = qsn;
      }
      else {
        const index = this.questionsarry1.indexOf(qsn);
        if (index > -1) {
          this.questionsarry1.splice(index, 1);
        }
        this.fourthquestion = '';
      }
    }
  }

  public previousquestions() {

    this.personaldetails = true;
    this.headertext = 'Verify your details to get accurate quotes';
  }

  public previousquestions1() {
    this.backbutton = this.allThreePopup ? true : false;
    this.nextquestions = false;
    this.nextnoneapply = false;
    this.nextpage = false;
    this.headertext = 'Almost there! Help us get you most accurate quotes from our 20+ carrier partners by answering these questions';
  }

  // public previous() {
  //   debugger

  //   this.headertext = 'Answer a few simple question to get Accurate Quotes';
  // }

  public async personnalnext() {

    debugger;
    this.headertext = 'Almost there! Help us get you most accurate quotes from our 20+ carrier partners by answering these questions';
    // this.personaldetails = false;
    this.pd_pop_up = 'true';
    this.showLoader = true;
    this.hubspotApplicationStage('Quote');
    this.getest(1);
    this.modalService.dismissAll();
    this.nextquestions = false;
    this.backbutton = true;
    this.prevEmailId = sessionStorage.getItem('bubble-email');
    if (this.emailid != this.prevEmailId && this.prevEmailId != null) {
      // replicated data
      console.log('calling replicateHubspotData');
      await this.pc.replicateHubspotData(this.prevEmailId, this.emailid);
    }
    localStorage.setItem('bubble-email', this.emailid);
    localStorage.setItem('bubble-email', this.emailid);
    localStorage.setItem('bubble-fname', this.firstname);
    localStorage.setItem('bubble-lname', this.lastname);
    localStorage.setItem('bubble-phone', this.phone);
    localStorage.setItem('bubble-heightFt', this.heightFt);
    localStorage.setItem('bubble-heightIn', this.heightIn);
    localStorage.setItem('bubble-weight', this.weight);
    localStorage.setItem('bubble-gender', this.sbligender);
    localStorage.setItem('bubble-dob', this.dob);
    let cov = Number(this.coverage);
    cov = cov / Number(this.termLength);
    cov = cov / 12;
    if (cov > 10000) {
      this.wnscoverage = 10000;
    } else if (cov < 500) {
      this.wnscoverage = 500;
    } else {
      this.wnscoverage = cov;
    }
    this.wnscoverage = this.wnscoverage.toString();
    if (this.wnscoverage.includes('.')) {
      const wnscov = this.wnscoverage.split('.');
      this.wnscoverage = wnscov[0];
    }
    this.ChangetobaccoUsage(this.tobacoUser);
    console.log('popupcommaninput', this.commonInput);
    const savedata = {
      'id': this.clientid,
      'email': this.emailid,
      'gender': this.sbligender,
      'bubble_email': this.emailid,
      'bubble_phone': this.phone,
      'dob': this.dob,
      'bubble_heightFt': this.heightFt,
      'bubble_heightIn': this.heightIn,
      'bubble_weight': this.weight,
      'tobacoselected': this.tobacoselected,
      'tobacoUsageDuration': this.tobacoUser,
      'tobacoUser': this.tobacoUser,
      'desired_life_term': this.termLength,
      'desired_life_coverage': this.coverage.toString(),
      'tobaccoUsage': this.tobacoUser,
      'recopageURL': window.location.href,
      'pd_pop_up': 'true',
    };
    this.pc.saveUpdateClientData(savedata);
    const hubSpotData: any = {
      email: this.emailid,
      phone: this.phone,
      dob: this.dob,
      gender: this.sbligender,
      height: this.pc.validateHeightHubspot(`${this.heightFt}'${this.heightIn}"`),
      weight: this.weight,
      tobacco_user: (this.tobacoUser == '5') ? 'No' : 'Yes',
      desired_life_coverage: this.coverage,
      desired_life_term: this.termLength,
      coverage_term_life: this.termLength,
      smoker: (this.tobacoUser == '5') ? 'No' : 'Yes'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    //this.getest(1);
    if (this.commonInput == undefined) {
      this.commonInput = {
        gender: this.sbligender == '1' ? 'M' : 'F',
        dob: this.dob,
        term: this.termLength,
        wnsterm: this.termLength,
        height: localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch',
        weight: +this.weight,
        back9hight: Number(localStorage.getItem('bubble-heightFt')) * 12 + Number(localStorage.getItem('bubble-heightIn')),
        tobaccoUse: localStorage.getItem('bubble-tobacco'),
        wnscoverage: this.wnscoverage.toString(),
        coverage: this.coverage.toString(),
        phone: this.phone.replace(/\$|-/g, '')
      };
    }

  }
  public next1() {
    debugger;
    this.questionSelected = this.questionsarry.length >= 1 || this.questionsarry1.length >= 1 ? true : false;
    this.koQuestionsShown = true;
    this.loader = true;
    const savedata = {
      'id': this.clientid,
      'clientId': this.clientid,
      'showPopup': 'false',
      'filtersbli': (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1) ? 'true' : 'false',
      'w_s__sbli_ko_popup_submission': 'true',
    };
    this.koSubmission = 'true';
    this.pc.saveUpdateClientData(savedata);
    this.policytype == '2' && this.questionSelected ? (this.wnsback9component.removesbliandwns()) : this.policytype == '4' && this.questionSelected ? this.Back9AllQuotesComponent?.removesbliandwns() : this.policytype == '1' && this.questionSelected ? this.termlifecomponent?.removesbliandwns() : 0;
    this.modalopened = false;
    this.modalService.dismissAll();
    this.loader = false;
    this.hubspotApplicationStage('Quote');
    // let filtersbli;
    // if (this.questionsarry.length >= 1 || this.questionsarry1.length >= 1) {
    //   filtersbli = true;
    //   this.commonInput['filtersbli'] = filtersbli;
    // }
    // else {
    //   filtersbli = false;
    //   this.commonInput['filtersbli'] = filtersbli;
    // }
    // setTimeout(() => {
    //   this.showLoader = true;
    //   this.Back9AllQuotesComponent.getAllQuotes(this.input_array, this.commonInput);
    // }, 10);
    // setTimeout(() => {
    //   if (this.policytype == '4') {
    //     this.Back9AllQuotesComponent.removesbliandwns();
    //   }
    //   else {
    //     this.termlifecomponent.removesbliandwns();
    //   }


    // }, 0);
  }
  public closed() {
    this.modalService.dismissAll();
    this.modalopened = false;
    this.notselectedquestions = true;
  }

  // public showsqlpopup() {
  //
  //   if (this.notselectedquestions && !this.modalopened) {
  //     this.openModal();
  //   }
  // }


  public closepopup() {
    this.popupclosed = true;
    this.schedulecall = false;
    document.getElementById('back9modal').style.display = 'none';
    this.back9modalopened = false;
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
  }
  public scheduleacall() {
    this.schedulecall = true;
  }
  callBack() {

    //this.schedulecall = true;
    this.callback_time = `${this.callback_hour}:${this.callback_minutes}:${this.selectedmeridum}`;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      callback_request__life_: 'Yes',
      appointment_date: this.appointmentdate,
      callback_time: this.callback_time,
      application_stage: 'Application',
      life_insurance_carrier: this.carrierName
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('back9modal').style.display = 'none';
    this.popupclosed = false;
    this.back9modalopened = false;
    document.getElementById('thankyouModalreco').style.display = 'block';
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
    this.pc.lifesummary({ 'callback_request': 'Yes' });
  }
  public callnow() {
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      call_initiated_by_life_user: 'Yes',
      application_stage: 'Application',
      life_insurance_carrier: this.carrierName
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.pc.lifesummary({ 'call_initiated': 'Yes' });
  }

  public async applyOnline() {
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_quote_amount: this.quoteamount,
      life_insurance_carrier: this.carriername,
    };
    await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('back9modal').style.display = 'none';
    this.popupclosed = true;
    this.back9modalopened = false;
  }

  public closeschedule() {
    this.schedulecall = false;
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
  }

  public closethankyou() {
    this.popupclosed = true;
    this.schedulecall = false;
    document.getElementById('thankyouModalreco').style.display = 'none';
  }

  public getHours(even) {

    if (even.target.value == '10' || even.target.value == '11' || even.target.value == '08' || even.target.value == '09') {
      if (even.target.value == '08') {
        this.minutes = ['30'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'AM';
    }
    else {
      if (even.target.value == '07') {
        this.minutes = ['00'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'PM';
    }
  }
  mindate: any;
  showback9popup(qts) {

    const d = new Date();
    const day = d.getDay();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    if (day < 6 && day > 0 && hours >= 8 && hours < 20) {
      if (hours == 19 && minutes >= 30) {
        this.Online = false;
      } else if (hours == 8 && minutes >= 30) {
        this.Online = true;
      }
      else {
        this.Online = true;
      }
    } else {
      this.Online = false;
    }
    this.back9modalopened = true;
    this.mindate = new Date();
    this.firstname = this.commonInput.firstName;
    this.lastname = this.commonInput.lastName;
    this.phone = this.commonInput.phone;
    this.coveragedisplay = qts.coverage;
    this.termdisplay = qts.term;
    this.emailid = localStorage.getItem('bubble-email');
    this.productname = qts.product['name'];
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
    this.redirecturl = qts.redirectUrl;
    this.selectedmeridum = 'PM';
    this.carriername = qts.carrierName;
    this.quoteamount = qts.quoteAmount;
    document.getElementById('back9modal').style.display = 'block';
  }

  _appointmentdate(appointmentdate) {

    const day = new Date(appointmentdate).getDay();
    if (day == 6 || day == 0) {
      this.dateValidation = 'Sat/Sun (Not Available)';
    } else {
      this.dateValidation = '';
    }

  }

  inputdatechange() {

    const K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
        this.is_valid = false;
      }
      else {
        this.message = '';
        this.is_valid = true;
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
          this.is_valid = false;
        }
        else if (A <= 12) {
          this.dob = A + '/' + B + '/';
          this.message = '';
          this.is_valid = true;
        }
        else {
          this.message = 'Invalid Date';
          this.is_valid = false;

        }


      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }

    if (this.dob.length >= 10 && this.message != 'Invalid Date') {
      this.is_valid = true;
      this.checkDateNew();
    }
    else {
      this.is_valid = false;
    }
  }
  checkDateNew() {

    const dob = new Date(this.dob);
    let year = Number(dob.getFullYear());
    let month = '' + (dob.getMonth() + 1);
    let day = '' + dob.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (month == 'NaN' || year.toString() == 'Nan' || month == 'NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
    }
    else {
      this.dob = this.pc.momentdob(month + '/' + day + '/' + year);

      console.log('DOB entered: ', dob);
      const checkAge = this.ValidationService.validate_dob('common_age', dob);
      console.log('DOB entered: checkAge', checkAge);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
      const d = new Date(this.dob);
      const cur_d = new Date();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      const ageYear = Number(curYear) - Number(year);
      if (ageYear >= 18 && ageYear <= 55) {
        this.is_valid = true;
      } else {
        this.is_valid = false;
      }
    }




  }

  selectgender(gen) {

    this.sbligender = gen;
    if (gen == '1') {
      localStorage.setItem('bubble-gender', '1');
      this.maleicon = 'assets/images/active/icon-male-active.svg';
      this.femaleicon = 'assets/images/images/ic-female.png';
    }
    else {
      localStorage.setItem('bubble-gender', '0');
      this.maleicon = 'assets/images/images/icon-male.png';
      this.femaleicon = 'assets/images/active/icon-female-active.svg';
    }

  }

  verifyInch() {
    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }

  public phonelength() {
    let count = 0;
    this.phone = this.phone.replace(/^0+/, '');
    this.phone = this.phone.replace(/\D/g, '');
    const phonestring = this.phone.toString().slice(0, this.phone.length);
    this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.phone = this.phone.slice(0, 12);
    if (this.phone.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }

  ChangetobaccoUsage(even) {

    this.tobacoUser = even;
    if (even === '5') {
      this.tobacoselected = 'No';
      localStorage.setItem('bubble-tobacco', '5');
    }
    else {
      this.tobacoselected = 'Yes';
      localStorage.setItem('bubble-tobacco', '0');
    }
    this.pc.lifesummary({ 'tobacco_user': this.tobacoselected });
  }

  Changedesired_life_coverage() {

    // if (this.coverage == null || this.coverage == undefined || this.coverage == '') {
    //   this.coverageError = 'This field is required';
    // } else {
    //   this.coverageError = '';
    // }

    if (Number(this.coverage) < 25000) {
      // this.coverage = '25000';
      this.coverageError = 'Coverage cannot be less than 25,000';
    }
    else if (Number(this.coverage) > 10000000) {
      //this.coverage = '10000000';
      this.coverageError = 'Coverage cannot be greater than 10,000,000';
    }
    else {
      this.coverageError = '';
    }
    if (this.coverage == null || this.coverage == undefined || this.coverage == '') {
      this.ShowDollar_income = false;
    } else {
      this.ShowDollar_income = true;
    }
    localStorage.setItem('bubble-coverage', this.coverage);
  }


  validateEmail() {
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.emailid;
    if (reg.test(emailtest) == false) {
      this.emailError = 'Please enter a valid email';
    }
    else {
      this.emailError = '';

    }
  }
  public termchange() {
    localStorage.setItem('bubble-termLength', this.termLength);
  }

  public async showkopopup(event) {
    this.backbutton = false;
    await this.pc.getAppData(this.clientid).then(data => {
      debugger;
      this.loader = false;
      const dataFrom = JSON.parse(data.Payload);
      this.showPopup = dataFrom.body.showPopup;
    }
    ).catch(error => {
      console.log('error', error);
    });
    console.log('eventtttttttttttt', event);
    this.wnssbliCarrier = event != 'wns' ? 'sbli' : 'wns';
    this.showPopup == 'false' ? (this.policytype == '1' ? this.termlifecomponent.redirectWnsSbliAfterPopup(this.wnssbliCarrier) : this.policytype == '2' ? this.wnsback9component.redirectWnsSbliAfterPopup(this.wnssbliCarrier) : this.Back9AllQuotesComponent.redirectWnsSbliAfterPopup(this.wnssbliCarrier)) : (this.personaldetails = false, this.openModal());
  }


  triggerPopupCondition() {
    debugger;
    this.popUpTriggered = true;
    this.modalopened = true;
    this.allFieldsAvailable = this.emailid && this.phone && this.dob && this.tobacoUser && this.height && this.weight ? true : false;
    console.log('  this.allFieldsAvailable', this.allFieldsAvailable, this.emailid, this.phone, this.dob, this.tobacoUser, this.height, this.weight);
    this.allFieldsAvailable && this.showPopup != 'false' ? (this.showLoader = true, this.getest(1), this.hubspotApplicationStage('Quote'), this.headertext = 'Almost there! Help us get you most accurate quotes from our 20+ carrier partners by answering these questions') : this.showPopup == 'false' ? (this.showLoader = true,
      this.getest(1), this.hubspotApplicationStage('Quote')) : (this.personaldetails = true, this.openModal(), this.allThreePopup = true, this.headertext = 'Verify your details to get accurate quotes', this.hubspotApplicationStage('Lead'));

  }


  hubspotApplicationStage(event?: any) {
    this.pc.hubSpotSyncAfterEmailFetch({
      email: localStorage.getItem('bubble-email'),
      user_landed_reco_page: event == 'Quote' ? 'Yes' : '',
      application_stage: event,
      short_url: this.shorturl,
      pd_pop_up: this.pd_pop_up == 'true' ? 'Submit' : '',
      w_s__sbli_ko_popup_submission: this.koSubmission == 'true' ? 'Submit' : '',
      life_insurance_type: `Term ${this.termLength} Years`
    });
  }

}
