import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from 'src/app/shared/validation.service.js';
import { createFalse } from 'typescript';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';

@Component({
  selector: 'app-wns6pt54',
  templateUrl: './wns6pt54.component.html',
  styleUrls: ['./wns6pt54.component.css']
})
export class Wns6pt54Component implements OnInit {
  questions: any;
  dynamicdiv = [];
  thirdParties: any;
  isDisable: boolean;
  FirstName: any;
  LastName: any;
  firstName: any;
  lastName: any;
  relationship: any;
  dateOfBirth: any;
  Address: any;
  email: any;
  Phone: any;
  message: string;
  disable: boolean;
  curDate: Date;
  dob: any;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  sbli_addBeneficiaryPercentage: number;
  showLoader = true;
  minDate;
  Grandtotal;
  addresstype: string;
  url: any;
  uniqueId: any;
  ShowSubQuestion: boolean;
  stateCheck: any;
  disableButtonIfEmpty: boolean;
  address: any;
  addresserror: boolean;
  constructor(private pc: ProcessCacheService, private activatedroute: ActivatedRoute, private validationService: ValidationService) { }

  ngOnInit(): void {
    debugger;
    this.url = window.location.href;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
    }
    );
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    console.log('fullAddressNew : ', fullAddress);
    this.ShowSubQuestion = true;
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    this.stateCheck = commonInput['state'];
    if (this.stateCheck == 'CA' || this.stateCheck == 'CT' || this.stateCheck == 'FL' || this.stateCheck == 'ID' || this.stateCheck == 'ME' || this.stateCheck == 'NJ' || this.stateCheck == 'RI' || this.stateCheck == 'VT') {
      this.ShowSubQuestion = true;
    } else {
      this.ShowSubQuestion = false;
    }

    // this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
    //   data => {
    //     debugger;
    //     console.log("splitAddressNew : ", data);
    //     this.stateCheck = data.state_code;
    //     if (this.stateCheck == 'CA' || this.stateCheck == 'CT' || this.stateCheck == 'FL' || this.stateCheck == 'ID' || this.stateCheck == 'ME' || this.stateCheck == 'NJ' || this.stateCheck == 'RI' || this.stateCheck == 'VT') {
    //       this.ShowSubQuestion = true;
    //     } else {
    //       this.ShowSubQuestion = false;
    //     }

    //   }
    // ).catch(error => console.log("error", error));

    this.EmailIDLabelDisplay = 'Invalid Email';
    this.addresstype = 'Home Address';
    this.clientId = localStorage.getItem('clientID');
    this.disableAddMore = false;
    this.isDisable = true;
    this.minDate = new Date();
    this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli6pt54')[0];
    // this.curDate = new Date();
    // const year = Number(this.curDate.getFullYear()) - 18;
    // let month = '' + (this.curDate.getMonth() + 1);
    // let day = '' + this.curDate.getDate();
    // if (month.length < 2)
    //   month = '0' + month;
    // if (day.length < 2)
    //   day = '0' + day;

    const qestentity = {
      'sbli_addBeneficiaryFirstName': '',
      'sbli_addBeneficiaryLastName': '',
      'sbli_addBeneficiaryRelationship': '',
      'sbli_addBeneficiaryDob': '',
      'sbli_addBeneficiaryPercentage': '',
      'sbli_addBeneficiaryConfirm': false,
      'doberror': '',
      'classchange': false
    };


    this.dynamicdiv.push(qestentity);

    let total = 0;
    this.dynamicdiv.forEach(element => {
      total += Number(element.sbli_addBeneficiaryPercentage);
    });
    this.Grandtotal = total.toLocaleString();
    localStorage.setItem('Bubble-grandTotal', this.Grandtotal);




    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData == undefined) {
        this.existingData = [];
      } else {

        if (this.existingData.sbli_beneficiaryInfo == undefined) {


        } else {
          this.Grandtotal = 100;
          this.disableAddMore = false;
          this.dynamicdiv = this.existingData.sbli_beneficiaryInfo;
          this.thirdParties = this.existingData.sbli_contact_for_premium_amount;

          if (this.thirdParties == 'Yes') {
            this.isDisable = false;
            this.showbutton = true;
            this.FirstName = this.existingData.sbli_contact_details.sbli_addBeneficiary_FirstName;
            this.LastName = this.existingData.sbli_contact_details.sbli_addBeneficiary_LastName;
            this.email = this.existingData.sbli_contact_details.sbli_addBeneficiary_EmailId;
            this.Phone = this.existingData.sbli_contact_details.sbli_addBeneficiary_DaytimePhone;
            this.city = this.existingData.sbli_contact_details.sbli_addBeneficiary_City;
            this.state = this.existingData.sbli_contact_details.sbli_addBeneficiary_State;
            this.zipcode = this.existingData.sbli_contact_details.sbli_addBeneficiary_Zipcode;
            this.address = this.existingData.sbli_contact_details.sbli_addBeneficiary_HomeAddress + ',' + this.city + ',' + this.state + ',' + this.zipcode + ',USA';
            this.EmailIDLabelDisplay = '';
          } else {
            this.showbutton = true;
            this.isDisable = false;
          }

        }

      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }





  showbutton: boolean;
  onKey(event, index, property) {
    debugger;

    if (event.target.value == '') {
      this.showbutton = false;
    } else {
      this.showbutton = true;
    }

    console.log('showbutton', this.showbutton);



    if (event.target.value > 100) {
      this.dynamicdiv[index][property] = 0;
      this.disableAddMore = false;
      let total = 0;
      this.dynamicdiv.forEach(e => {
        total += Number(e.sbli_addBeneficiaryPercentage);
      });
      this.Grandtotal = Number(total.toLocaleString());
    } else {
      this.dynamicdiv[index][property] = event.target.value;
      let total = 0;
      this.dynamicdiv.forEach(e => {
        total += Number(e.sbli_addBeneficiaryPercentage);
      });
      this.Grandtotal = Number(total.toLocaleString());
      // this.disableAddMore = true;
    }

    if (this.Grandtotal > 100) {
      this.disableAddMore = false;
      this.dynamicdiv[index][property] = 0;

      let total = 0;

      this.dynamicdiv.forEach(e => {
        total += Number(e.sbli_addBeneficiaryPercentage);
      });
      this.Grandtotal = Number(total.toLocaleString());

      if (this.Grandtotal > 100) {
        this.dynamicdiv[index][property] = 0;
      }




      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    } else {
      // this.disableAddMore = true;
      let total = 0;

      this.dynamicdiv.forEach(e => {
        total += Number(e.sbli_addBeneficiaryPercentage);
      });
      this.Grandtotal = Number(total.toLocaleString());
      if (this.Grandtotal > 100) {
        this.dynamicdiv[index][property] = 0;
      }
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    }


    const gdhsgd = this.dynamicdiv;

    if (this.dynamicdiv[this.dynamicdiv.length - 1].sbli_addBeneficiaryFirstName != ''
      && this.dynamicdiv[this.dynamicdiv.length - 1].sbli_addBeneficiaryLastName != ''
      && this.dynamicdiv[this.dynamicdiv.length - 1].sbli_addBeneficiaryRelationship != 'Select'
      && this.dynamicdiv[this.dynamicdiv.length - 1].sbli_addBeneficiaryPercentage != '') {
      this.disableAddMore = true;
    } else {
      this.disableAddMore = false;
    }


    if (this.Grandtotal == 100) {
      this.disableAddMore = false;
    }




  }


  invalidDate: boolean;
  is_valid: boolean;
  onKeyFordate(even, div, index, property) {
    debugger;
    //   if(newDate==null || newDate==undefined){
    //     this.dynamicdiv[index][property]= new Date();
    //     newDate=new Date();
    //   }
    //   debugger;
    //   var cur_d = new Date();
    //   var d = new Date(newDate),
    //   month = '' + (d.getMonth() + 1),
    //   day = '' + d.getDate(),
    //   year = d.getFullYear();
    //   if (month.length < 2)
    //   month = '0' + month;
    //  if (day.length < 2)
    //   day = '0' + day;
    //   var curYear =  cur_d.getFullYear();
    //   year = d.getFullYear();

    //   var ageYear= Number(curYear) - Number(year)
    //   this.dynamicdiv[index][property] = month + '/'+ day + '/' + year;
    //   if(ageYear>=18){
    //    this.invalidDate=false;
    //   }else{
    //     this.invalidDate=true
    //   }

    if (even == null) {
      // this.curDate = new Date();
      // let year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      //this.dynamicdiv[index][property] = month + '/' + day + '/' + year;
      this.dynamicdiv[index][property] = this.pc.momentdob(even);
    } else {
      // var cur_d = new Date();
      // var d = new Date(even),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear();
      // var curYear = cur_d.getFullYear();
      // year = d.getFullYear();
      // var ageYear = Number(curYear) - Number(year)
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dynamicdiv[index][property] = month + '/' + day + '/' + year;
      this.dynamicdiv[index][property] = this.pc.momentdob(even);
    }

    if (div.sbli_addBeneficiaryDob == 'NaN/NaN/NaN') {
      div.doberror = 'Invalid Date';
      this.is_valid = false;
      div.sbli_addBeneficiaryDob = '';
    }
    else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      // const checkAge = this.validationService.validate_dob('common_age', newDate);
      // div.doberror = checkAge.message;
      // this.is_valid = checkAge.is_valid;
      // this.message = checkAge.message;
    }


    // let newDate = new Date(even)
    // console.log("DOB entered: ", newDate);
    // let checkAge = this.validationService.validate_dob("beneficiary_dob", newDate);
    // this.message = checkAge.message;
    // this.is_valid = checkAge.is_valid;



  }

  disableAddMore: boolean;
  adddiv() {
    debugger;
    if (this.Grandtotal < 100) {

      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.sbli_addBeneficiaryPercentage);
      });
      this.Grandtotal = total1.toLocaleString();
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      const qestentity = {
        'sbli_addBeneficiaryFirstName': '',
        'sbli_addBeneficiaryLastName': '',
        'sbli_addBeneficiaryRelationship': '',
        'sbli_addBeneficiaryDob': '',
        'sbli_addBeneficiaryPercentage': '',
        'sbli_addBeneficiaryConfirm': false,
        'doberror': '',
        'classchange': false
      };

      this.dynamicdiv.push(qestentity);




      if (this.Grandtotal == 100) {
        this.disableAddMore = true;
      } else {
        this.disableAddMore = false;
      }

    } else {
      if (this.Grandtotal == 100) {
        this.disableAddMore = true;
      } else {
        this.disableAddMore = false;
      }


    }

  }

  thirdParty(input) {
    debugger;
    this.thirdParties = input;


    if (this.thirdParties == 'Yes') {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }



  }

  public relationType(even, div) {
    debugger;
    if (even.target.value == 'Parent' || even.target.value == 'Sibling') {
      div['classchange'] = true;
    }
    else {
      div['classchange'] = false;
    }
  }
  deleteDiv(index) {
    if (this.dynamicdiv.length == 1 && index == 0) {
      this.dynamicdiv.splice(index, 1);
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      const qestentity = {
        'sbli_addBeneficiaryFirstName': '',
        'sbli_addBeneficiaryLastName': '',
        'sbli_addBeneficiaryRelationship': '',
        'sbli_addBeneficiaryDob': '',
        'sbli_addBeneficiaryPercentage': '',
        'sbli_addBeneficiaryConfirm': false,
        'doberror': '',
        'classchange': false
      };
      this.dynamicdiv.push(qestentity);
    }
    else {
      this.dynamicdiv.splice(index, 1);
    }


    let total1 = 0;
    this.dynamicdiv.forEach(element => {
      total1 += Number(element.sbli_addBeneficiaryPercentage);
    });
    this.Grandtotal = total1.toLocaleString();


    if (this.Grandtotal < 100) {
      this.disableAddMore = true;
    } else {
      this.disableAddMore = false;
    }


    console.log(this.dynamicdiv);
  }

  state: any;
  city: any;
  zipcode: any;
  homeAddress: any;
  public handleAddressChange(address) {
    debugger;
    this.dynamicdiv[0].sbli_addBeneficiaryFirstName;
    let county = '';//'Autauga County';
    let stateCodeLocal = '';//'AL';
    let city = '';//'Autauga';
    let zip = '';
    let street = '';
    let country = '';
    for (let i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types) {
        if (address.address_components[i].types.includes('street_number')) {
          street = street + address.address_components[i].short_name + ' ';
        }
        else if (address.address_components[i].types.includes('route')) {
          street = street + address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_2')) {
          county = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('locality')) {
          city = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_1')) {
          stateCodeLocal = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('postal_code')) {
          zip = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('country')) {
          country = address.address_components[i].short_name;
        }
      }
    }

    this.state = stateCodeLocal;
    this.city = city;
    this.zipcode = zip;
    this.homeAddress = street;
    if (this.state == '' || this.city == '' || this.zipcode == '' || this.homeAddress == '') {
      this.addresserror = true;
    }
    else {
      this.addresserror = false;
    }

  }



  disableButton() {
    this.state = '';
    this.city = '';
    this.zipcode = '';
    this.homeAddress = '';
  }


  phoneerror: boolean;

  phonelength() {
    debugger;
    if (this.Phone.length === 3) {
      this.Phone = this.Phone + '-';
      this.phoneerror = true;
    }
    else if (this.Phone.length === 7) {
      this.Phone = this.Phone + '-';
      this.phoneerror = true;
    }
    else if (this.Phone.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }


  next() {
    debugger;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };


    json.SBLI[this.questions.group_key] = this.dynamicdiv;


    const sblicontactdetails = {
      'sbli_addBeneficiary_FirstName': this.FirstName,
      'sbli_addBeneficiary_LastName': this.LastName,
      'sbli_addBeneficiary_HomeAddress': this.homeAddress,
      'sbli_addBeneficiary_City': this.city,
      'sbli_addBeneficiary_State': this.state,
      'sbli_addBeneficiary_Zipcode': this.zipcode,
      'sbli_addBeneficiary_EmailId': this.email,
      'sbli_addBeneficiary_DaytimePhone': this.Phone


    };

    if (this.thirdParties == 'Yes') {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.thirdParties;

      // json.SBLI[this.questions.group_of_inputs_2.answer_1.answer_key] = this.FirstName;
      // json.SBLI[this.questions.group_of_inputs_2.answer_2.answer_key] = this.LastName;
      // json.SBLI[this.questions.group_of_inputs_2.answer_3.answer_key] = this.Address;
      // json.SBLI[this.questions.group_of_inputs_2.answer_4.answer_key] = this.email;
      // json.SBLI[this.questions.group_of_inputs_2.answer_5.answer_key] = this.Phone;

      json.SBLI['sbli_contact_details'] = sblicontactdetails;



    } else if (this.thirdParties == 'No') {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.thirdParties;
    }

    json.SBLI['sbli_PolicyNumber'] = '';

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1,
      'APICall': 3,
      'calledfrombene': true,
      'Benecalled': true
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

  EmailIDLabelDisplay;
  CheckEmailID() {
    debugger;

    const div = document.getElementById('EmailIDSpan');
    if (document.getElementById('EmailIDSpan') != null) {
      this.EmailIDLabelDisplay = div.innerText;
    } else {
      this.EmailIDLabelDisplay = '';
    }


  }

  inputdatechange(div, index, property) {
    debugger;
    const K = div.sbli_addBeneficiaryDob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        div.doberror = 'Invalid Date';
      }
      else {
        div.doberror = '';
      }
      div.sbli_addBeneficiaryDob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          div.doberror = 'Invalid Date';
        }
        if (B.toString() == '00') {
          div.doberror = 'Invalid Date';
        }
        else if (A <= 12) {
          div.sbli_addBeneficiaryDob = A + '/' + B + '/';
          this.message = '';
        }
        else {
          div.doberror = 'Invalid Date';
        }
      }
      else {
        div.sbli_addBeneficiaryDob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      if (B > 31 || A > 12) {
        div.sbli_addBeneficiaryDob = A + '/' + B + '/' + C;
        div.doberror = 'Invalid Date';
      }
      else {
        div.sbli_addBeneficiaryDob = A + '/' + B + '/' + C;
        div.doberror = '';
      }
    }
    else {
      div.sbli_addBeneficiaryDob = div.sbli_addBeneficiaryDob;
    }
    if (div.sbli_addBeneficiaryDob.length >= 10 && div.doberror != 'Invalid Date') {
      div.doberror = this.pc.checkdate(div.sbli_addBeneficiaryDob);
      this.message = div.doberror;
    }
    else {
      this.is_valid = false;
    }
  }


  moveDatePicker(i) {
    debugger;
    setTimeout(() => {
      const x = document.getElementsByTagName('bs-datepicker-container')[0];
      console.log(x);
      document.getElementById(i).appendChild(x);
    }, 100);
  }

}
